import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout";
import Posts from "../components/Posts";
import { Post } from "../components/Post"

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!) {
    posts: allMdx(filter: {frontmatter: {draft: {ne: true}, tags: {in: [$tag]}}}, sort: {fields: frontmatter___date, order: DESC}) {
      ...PostFragment
    }
  }

  fragment PostByTagFragment on MdxConnection {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tags
          category
          date
          niceDate: date(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`;

interface PostConnection {
  node: {
    body: any
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      tags: string[]
      category: string
      date: string
      niceDate: string
    }
  }
}

interface Props {
  data: {
    posts: {
      edges: PostConnection[]
    }
  },
  pageContext: {
    tag: String
  }
}

const convertNodeToPost = (postNodes: PostConnection[]) => {
  const postList: Post[] = [];
  postNodes.forEach(postEdge => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.frontmatter.date,
      niceDate: postEdge.node.frontmatter.niceDate,
      body: postEdge.node.body
    });
  });
  return postList;
}

export default ({ data, pageContext: { tag } }: Props) => {
  return (
    <Layout>
      <Posts title={`“${tag}”`} posts={convertNodeToPost(data.posts.edges)} />
    </Layout>
  );
}


