import React from "react";
import PostArticle, { Post } from "./Post";

interface Props {
  posts: Post[]
  title: String
}

export default ({ posts, title = "Posts" }: Props) => {
  return (
    <section className="Posts">
      <section className="Posts-list">
        {posts.map(post => (
          <PostArticle post={post} excerpt={true} />
        ))}
      </section>

    </section>
  );
}

